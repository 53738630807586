import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import BlogPreview from '../components/BlogPreview'
import EventsOverview from '../components/EventsOverview'
import MvTextPageSection from '../components/common/MvTextPageSection'
import MvTextPage from '../components/common/MvTextPage'

export default ({ data }) => (
  <Layout>
    <MvTextPage title="Aktuelles">
      <MvTextPageSection title="Termine" fullWidth>
        <EventsOverview
          events={data.allEventsJson.edges.map(({ node }) => node)}
        />
      </MvTextPageSection>

      <MvTextPageSection title="Berichte" fullWidth>
        <div className="flex justify-start flex-wrap">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <BlogPreview
              title={node.frontmatter.title}
              date={node.frontmatter.date}
              path={node.fields.slug}
              key={node.id}
              className="p-6 w-full sm:w-1/2 md:w-1/3 grow-0"
            >
              {node.excerpt}
            </BlogPreview>
          ))}
        </div>
      </MvTextPageSection>
    </MvTextPage>
  </Layout>
)

export const query = graphql`
  query {
    allEventsJson {
      edges {
        node {
          title
          start
          end
          description
          location
          cancelled
        }
      }
    }
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "D. MMMM YYYY", locale: "de-DE")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
