import React from 'react'
import { Link } from 'gatsby'

export default ({ date, title, path, children, className }) => {
  return (
    <div className={className + ' flex flex-col p-4'} data-label="blog-preview">
      <div className="text-grey">{date}</div>
      <h3 className="m-0 mb-4">{title}</h3>
      <p>{children}</p>
      <Link to={path} className="self-end mt-4" data-label="read-more-link">
        weiterlesen
      </Link>
    </div>
  )
}
